<template>
  <div>
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <v-layout justify-space-between style="align-items: self-end">
        <v-layout class="col-12 pa-0 mr-2" style="display: flex">
          <div class="col-3 pl-0">
            <span>
              {{ $t('code-name-phone') }}
            </span>
            <v-text-field
              v-model="objSearch"
              outlined
              dense
              hide-details
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="`${$t('place_holders.way_bill')}/${$t('name')}/${$t(
                'receiver_phone'
              )}`"
              @keydown.enter="onClickSearch"
            />
          </div>
          <div v-if="lstPostOffice.length > 0" class="col-3">
            <span>
              {{ $t('post_offices') }}
            </span>
            <v-autocomplete
              v-model="selectPostOffice"
              outlined
              dense
              hide-details
              clearable
              :no-data-text="$t('no-data')"
              :placeholder="$t('place_holders.choice-post-office')"
              :items="lstPostOffice"
              :filter="filterItems"
              item-text="name"
              item-value="id"
              @change="onChangeOffice"
            />
          </div>
          <div class="col-3">
            <span>
              {{ $t('shop') }}
            </span>
            <v-autocomplete
              v-model="shopSelected"
              outlined
              dense
              hide-details
              multiple
              clearable
              :no-data-text="$t('no-data')"
              :placeholder="$t('place_holders.shop')"
              :items="shopItems"
              :search-input.sync="searchShop"
              item-text="name"
              item-value="id"
              @change="searchShop = ''"
            >
              <template v-slot:item="{ item }">
                <span class="mr-1"> {{ getTextTruncate(item.name, 30) }} </span>
                <span> ( {{ item.poName }} ) </span>
              </template>
            </v-autocomplete>
          </div>
          <div v-if="lstPostOffice.length === 0" class="col-3" />
          <div class="mt-6 col-3 pr-0" style="text-align: end">
            <v-btn width="150px" color="primary" @click="onClickSearch">
              {{ $t("search") }}
            </v-btn>
          </div>
        </v-layout>
      </v-layout>
      <v-layout class="mb-1">
        <v-expansion-panels v-model="expanded" class="expansion-panels no-z-index">
          <v-expansion-panel>
            <v-expansion-panel-header class="pr-0">
              <div style="text-align: end">
                {{ $t("advanceSearch") }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0 pb-0">
              <v-layout justify-space-between>
                <v-layout class="col-12 pa-0 mr-2" style="display: flex; justify-content: space-between">
                  <div v-if="showAllTab" class="col-3 pl-0 pt-0">
                    <span v-if="showAllTab">
                      {{ $t('status-order') }}
                    </span>
                    <v-select
                      v-if="showAllTab"
                      v-model="statusSelected"
                      outlined
                      dense
                      hide-details
                      :placeholder="$t('place_holders.order_status')"
                      :items="statusItems"
                      item-text="name"
                      item-value="code"
                      multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="toggleStatusSearch"
                        >
                          <v-list-item-action>
                            <v-icon :color="statusSelected.length > 0 ? 'primary' : ''">
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t("all") }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ index }">
                        <label v-if="statusSelected && index === 0">
                          {{ $t("selectd_status") }}
                          <span class="grey--text text-caption">
                            ({{ statusSelected.length }})
                          </span>
                        </label>
                      </template>
                    </v-select>
                  </div>
                  <div :class="!showAllTab ? 'col-3 pr-3 pt-0 pl-0' : 'col-3 pr-3 pt-0'">
                    <span>
                      {{ $t('group-shipping') }}
                    </span>
                    <v-select
                      v-model="partnerSelected"
                      outlined
                      dense
                      hide-details
                      class="select-groups"
                      :placeholder="$t('place_holders.choice_group_shipping')"
                      solo
                      style=""
                      :items="lstPartner"
                      item-text="partnerName"
                      item-value="partnerId"
                    />
                  </div>
                  <v-layout column class="col-3 pt-0">
                    <span>
                      {{ $t('create-date') }}
                    </span>
                    <DateRangerPicker
                      ref="datePickerCreate"
                      @setDateFrom="setFromDateCreate"
                      @setDateTo="setToDateCreate"
                    />
                  </v-layout>
                  <v-layout column class="col-3 pt-0">
                    <span>
                      {{ $t('last-action') }}
                    </span>
                    <DateRangerPicker
                      ref="datePickerEnd"
                      @setDateFrom="setFromDateEnd"
                      @setDateTo="setToDateEnd"
                    />
                  </v-layout>
                  <v-layout v-if="!showAllTab" class="col-3 pt-0" />
                </v-layout>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-layout>
      <v-layout v-if="countSelected > 0" align-center class="mb-2">
        <label class="mr-4">
          {{ $t("selected") }}:
          {{ countSelected }}
        </label>
        <v-radio-group
          v-model="typeExportSelected"
          row
          class="type-export-select my-0 py-0"
        >
          <v-radio label="Excel" :value="typeExportConst.excel" class="my-0" />
          <v-radio label="CSV" :value="typeExportConst.csv" class="my-0" />
        </v-radio-group>
        <v-btn
          color="primary"
          outlined
          class="mr-4 mb-2 mb-sm-0"
          @click="handleDownload"
        >
          <v-icon size="20" class="mr-2">mdi-tray-arrow-down</v-icon>
          {{ $t("export_excel") }}
        </v-btn>
        <v-btn
          v-if="isShowBtnDel"
          color="primary"
          outlined
          class="mr-4"
          @click="onShowConfirmDeleteItems"
        >
          <v-icon size="20" class="mr-2">mdi-close-box-outline</v-icon>
          {{ $t("cancel_order") }}
        </v-btn>
        <v-btn
          v-if="isShowBtnPrint"
          color="primary"
          @click="onViewWayBillNewTab"
        >
          <v-icon size="20" class="mr-2">mdi-printer-outline</v-icon>
          {{ $t("print_bill_of_lading") }}
        </v-btn>
      </v-layout>
      <v-divider style="border-color: red" />
      <v-layout class="pb-1">
        <v-tabs color="black" class="change-color-tab" fixed-tabs show-arrows>
          <v-tab v-for="(item, index) in statusList" :key="index" class="tab-status" @change="searchList(item.code, index)">
            <span class="text-transform-none" style="color:black">{{ item.name }}</span>
            <span v-if="item.totalCount" style="color: red">({{ item.totalCount }})</span>
          </v-tab>
        </v-tabs>
      </v-layout>
      <div class="box-list-content-order">
        <v-data-table
          id="virtual-scroll-table"
          v-model="selected"
          v-scroll:#virtual-scroll-table="onScroll"
          :show-select="!checkRole"
          class="border-primary"
          hide-default-footer
          :fixed-header="true"
          :height="heightTable"
          :no-data-text="$t('not_found')"
          :headers="headers"
          :header-props="{ sortIcon: 'mdi-menu-swap' }"
          :items="items"
          :items-per-page="items.length"
        >
          <template v-slot:[`item.deliveryCode`]="{ item }">
            <td class="max-width-6 text-truncate">
              <a
                :href="getHrefLinkById(item.id)"
                target="_blank"
                class="text-decoration-none"
                :class="checkRole ? 'link-default' : ''"
              >
                {{ item.deliveryCode }}
              </a>
            </td>
          </template>
          <template v-slot:[`item.partnerName`]="{ item }">
            <tr class="max-width-3 text-truncate">
              {{ item.partnerName }}
            </tr>
          </template>
          <template v-slot:[`item.receiverName`]="{ item }">
            <td class="max-width-4 text-truncate">
              {{ item.receiverName }}
            </td>
            <tr class="text--body-4-12">
              <v-icon size="12">mdi-phone-in-talk-outline</v-icon>
              {{ item.receiverPhone }}
            </tr>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDateView(item.createdAt) }}
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ formatDateView(item.updatedAt) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <td class="max-width-3 text-truncate">
              {{ item.statusDescription }}
            </td>
          </template>
          <template v-slot:[`item.codValue`]="{ item }">
            <td class="max-width-5 text-truncate">
              {{ formatDecimalView(item.codValue) }}
            </td>
          </template>
          <template v-slot:[`item.paymentBy`]="{ item }">
            <td class="max-width-5 text-truncate">
              {{ getPaymentByText(item.paymentBy) }}
            </td>
          </template>
          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox
              :value="props.value || props.indeterminate"
              :indeterminate="props.indeterminate"
              color="primary"
              v-on="on"
            />
          </template>
          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox
              v-ripple
              color="primary"
              :value="isSelected"
              @input="select($event)"
            />
          </template>
        </v-data-table>
      </div>
      <v-row justify-space-between align-center class="mx-0 mt-0">
        <v-layout align-center class="col-12 col-sm-3 col-md-6 pb-0">
          <label class="label-center">
            {{ $t("display") }} {{ computedStartRecord }} -
            {{ computedEndRecord }} /
            {{ totalRecord }}
          </label>
        </v-layout>
      </v-row>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />

    <!-- dialog confirm del -->
    <dialog-confirm
      v-model="showConfirmDel"
      :icon-src="getIcon"
      :message="message"
      :text-cancel="textCancel"
      :text-accept="textAccept"
      :on-cancel="onCancelConfirmDel"
      :on-accept="onAcceptConfirmDel"
    />

    <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
  </div>
</template>

<script>
import Breadscrumbs from '@/components/base/Breadscrumbs'
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import DialogConfirmTokenExpried from "@/components/dialog/DialogConfirmTokenExpried.vue";
import DateRangerPicker from "@/views/order/components/DateRangerPicker"
import { StringUtils } from "@/helpers/stringUtils";
import { DateTimeUtils } from "@/helpers/dateTimeUtils";
import { DataUtils } from "@/helpers/dataUtils";
import constants from "@/constants";
import { OrderService } from "@/services/orderService";
import routePaths from "@/router/routePaths";
import moment from "moment";
import mixinStatusOrder from "./mixins/mixinStatusOrder"
import filterItems from "@/helpers/filterItems";
import { StorageService } from "@/services/storageService";
import { ReportService } from "@/services/reportService";
import { UserService } from "@/services/userService";
import { mapActions } from "vuex";
import { DataBreadCrumbUtils } from "@/helpers/dataBreadCrumbUtils";
import { PostService } from "@/services/postService";
import { BaseService } from "@/services/baseService";

export default {
  components: {
    DialogLoading,
    DialogNotification,
    DialogConfirm,
    DialogConfirmTokenExpried,
    DateRangerPicker,
    Breadscrumbs
  },
  mixins: [mixinStatusOrder, filterItems],
  data() {
    return {
      constants,
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
      serviceFeePayersConst: constants.serviceFeePayers,
      objSearch: "",
      shopSelected: null,
      shopItems: [],
      menu: false,
      dates: [],
      datesFm: [],
      fromDateCreate: null,
      toDateCreate: null,
      fromDateEnd: null,
      toDateEnd: null,
      statusSelected: "",
      statusItems: [],

      selected: [],
      headers: [
        {
          text: this.$t("way_bill"),
          value: "deliveryCode",
          width: "16%",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("receiver_name"),
          value: "receiverName",
          width: "12%",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("group-shipping"),
          sortable: false,
          value: "partnerName",
          width: "10%",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("createdTime"),
          value: "createdAt",
          width: "10%",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("last_action"),
          value: "updatedAt",
          width: "12%",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("status"),
          value: "status",
          width: "14%",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("money_cod"),
          sortable: false,
          value: "codValue",
          width: "8%",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("payment_by"),
          sortable: false,
          value: "paymentBy",
          width: "8%",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("shop_name"),
          sortable: false,
          value: "shopName",
          width: "10%",
          class: "text-subtitle-2",
        },
      ],
      lstPartner: [],
      partnerSelected: null,
      items: [],
      totalPage: 0,
      page: 1,
      totalRecord: 0,
      itemsPerPage: constants.pageSizeMax,

      // notifications
      showNoti: false,
      typeNoti: constants.typeAlert.warning,
      message: "",

      showLoading: false,

      showConfirmDel: false,
      textCancel: this.$t("cancel"),
      textAccept: this.$t("cancel_order"),

      showConfirmTokenExpried: false,
      timeout: null,

      typeExportConst: constants.typeExport,
      typeExportSelected: constants.typeExport.excel,
      headingExport: [
        [
          "Tên shop",
          "Mã vận đơn",
          "Đơn vị vận chuyển",
          "Thời gian tạo",
          "Thao tác cuối",
          "Trạng thái đơn hàng",
          "Tên người gửi",
          "Địa chỉ gửi hàng",
          "Tỉnh gửi hàng",
          "SĐT người gửi",
          "Địa chỉ nhận hàng",
          "Tỉnh nhận hàng",
          "Tên người nhận",
          "SĐT người nhận",
          "Tên hàng hóa",
          "Giá trị hàng hóa",
          "Khối lượng khi tạo đơn",
          "Khối lượng tính phí",
          "Tiền COD",
          "Phí vận chuyển",
          "Phí dịch vụ",
          "Tổng phí dịch vụ",
          "Bảo hiểm",
          "Phí bảo hiểm",
          "Đối tượng trả phí dịch vụ",
          "Người trả cước",
          "Tổng thu người nhận",
        ],
      ],
      isScroll: false,
      breadcrumbs: [
        {
          text: 'order_list',
          disabled: true,
          isActive: true,
        },
      ],
      tabStatus: "",
      statusSelectItem: "",
      // TODO
      statusList: [],
      returnPartPackage: null,
      currentTab: null,
      showAllTab: true,
      expanded: 1,
      lstPostOffice: [],
      selectPostOffice: null,
      searchShop: ''
    };
  },
  computed: {
    checkRole() {
      return this.currentRole.includes("ROLE_ACCOUNTANT")
    },
    minAllowedDate() {
      if (this.dates && this.dates.length === 1) {
        return this.dates[0];
      }
      return null;
    },
    maxAllowedDate() {
      return moment().format(constants.formatDateYmd);
    },
    dateRangeText() {
      return this.datesFm.join(" - ");
    },
    computedHeightTable() {
      const box = document.getElementById("virtual-scroll-table");
      return box.offsetHeight;
    },
    computedStartRecord() {
      if (this.items !== null && this.items.length > 0) {
        return 1;
      } else {
        return 0;
      }
    },
    computedEndRecord() {
      return this.items.length;
    },
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti);
    },
    countSelected() {
      return this.selected.length;
    },
    isShowBtnDownload() {
      return this.selected.length > 0;
    },
    isShowBtnDel() {
      if (this.countSelected === 0) return false;

      const countStatus = this.selected.filter(
        (x) => x.status === constants.orderStatus.waiting
      ).length;
      return countStatus === this.countSelected;
    },
    isShowBtnPrint() {
      if (this.countSelected === 0) return false;

      const countStatus = this.selected.filter(
        (x) => x.status === constants.orderStatus.waiting
      ).length;
      return countStatus === this.countSelected;
    },
    getFileName() {
      return `export_orders_${DateTimeUtils.formatDateTime(
        new Date(),
        constants.formatDateTimeInt
      )}.${this.typeExportSelected}`;
    },
    likesAllStatus() {
      return this.statusSelected.length === this.statusItems.length;
    },
    likesSomeStatus() {
      return this.statusSelected.length > 0 && !this.likesAllStatus;
    },
    icon() {
      if (this.likesAllStatus) return "mdi-close-box";
      if (this.likesSomeStatus) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    isLoadMore() {
      return this.items.length < this.totalRecord;
    },
    heightTable() {
      if (this.expanded === 0) {
        return "55vh"
      } return "62vh"
    }
  },
  watch: {
    expanded() {
      if (this.expanded === 0) {
        this.setDateDefault()
      }
    },
    dates(val) {
      const newDates = [];
      val.forEach((i) => {
        const item = this.formatDate(i);
        newDates.push(item);
      });
      this.datesFm = newDates;
    },
  },
  beforeMount() {
    this.initData();
  },
  methods: {
    ...mapActions("layout", ["setBreadcrumbs"]),
    async initData() {
      await Promise.all([
        this.getListStatus(),
        this.getPartners(),
        this.searchPostOffice()])
      this.getShops();
    },
    setDateDefault() {
      const toDateDefault = moment().format('DD/MM/YYYY')
      this.toDateCreate = moment().format('YYYY-MM-DD')
      setTimeout(() => {
        if (this.$refs.datePickerCreate) {
          this.$refs.datePickerCreate.setDateCreate(toDateDefault)
        }
      }, 200);
    },
    setFromDateCreate(val) {
      if (val) {
        this.fromDateCreate = val.split("/").reverse().join("-");
      } else {
        this.fromDateCreate = val;
      }
    },
    setToDateCreate(val) {
      if (val) {
        this.toDateCreate = val.split("/").reverse().join("-");
      } else {
        this.toDateCreate = val;
      }
    },
    setFromDateEnd(val) {
      if (val) {
        this.fromDateEnd = val.split("/").reverse().join("-");
      } else {
        this.fromDateEnd = val;
      }
    },
    setToDateEnd(val) {
      if (val) {
        this.toDateEnd = val.split("/").reverse().join("-");
      } else {
        this.toDateEnd = val;
      }
    },
    onClickSearch() {
      document.getElementById("virtual-scroll-table").scrollTo(0, 0);
      this.selected = []
      this.page = 1;
      this.currentTab
      this.searchOrders();
    },
    toggleStatusSearch() {
      this.$nextTick(() => {
        if (this.likesAllStatus) {
          this.statusSelected = [];
        } else {
          this.statusSelected = this.bindStatusCodes(this.statusItems);
        }
      });
    },
    bindStatusCodes(items) {
      const results = [];
      items.forEach((item) => {
        results.push(item.code);
      });
      return results;
    },
    async searchOrders() {
      const pars = this.bindRequestPars();
      this.showLoading = true
      const { status, data } = await OrderService.searchOrders(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok) {
        this.totalRecord = data.totalRecord;
        setTimeout(() => {
          if (!this.currentTab) {
            this.statusList[0].totalCount = this.totalRecord
          } else {
            this.statusList[this.currentTab].totalCount = this.totalRecord
          }
          this.$forceUpdate()
        }, 200)
        this.totalPage = DataUtils.calculatePageByItemsPerPage(
          data.totalRecord,
          this.itemsPerPage
        );
        if (this.page > 1) {
          this.items = this.items.concat(data.data);
        } else if (data.totalRecord > 0) {
          this.items = data.data;
        } else {
          this.items = []
        }
        this.isScroll = false;
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    bindRequestPars() {
      const pars = {
        currentPage: this.page,
        limit: this.itemsPerPage,
        objSearch: "",
      };

      if (this.objSearch) {
        pars.objSearch = this.objSearch.trim();
      }
      if (this.fromDateCreate) {
        pars["fromDate"] = this.fromDateCreate;
      }
      if (this.toDateCreate) {
        pars["toDate"] = this.toDateCreate;
      }

      if (!this.toDateCreate) {
        if (this.expanded === 1) {
          this.setDateDefault()
        }
        pars["fromDate"] = this.fromDateCreate;
        pars["toDate"] = this.toDateCreate;
      }

      if (this.fromDateEnd) {
        pars["fromUpdateDate"] = this.fromDateEnd;
      }
      if (this.toDateEnd) {
        pars["toUpdateDate"] = this.toDateEnd;
      }
      if (this.partnerSelected) {
        pars["partnerId"] = this.partnerSelected
      }
      if (this.statusSelected.length > 0) {
        pars["statusList"] = this.statusSelected;
      }
      if (this.statusSelectItem && this.statusSelectItem.length < 2) {
        pars["statusList"] = this.statusSelectItem;
      }
      if (this.returnPartPackage) {
        pars["returnPartPackage"] = 1
      }

      if (this.shopSelected && this.shopSelected.length) {
        pars["custIds"] = this.shopSelected;
      }
      if (this.selectPostOffice) {
        pars["poId"] = this.selectPostOffice
      }
      return pars;
    },
    async searchList(item, index) {
      this.page = 1;
      this.statusSelectItem = ''
      this.currentTab = index;
      if (item === 99) {
        this.showAllTab = false
        this.returnPartPackage = 1
      } else if (item !== null && item !== "undefined" && item !== 99) {
        this.showAllTab = false
        this.statusSelectItem = [item]
        this.returnPartPackage = ''
      } else if (item === null) {
        this.showAllTab = true
        this.returnPartPackage = ''
      }
      await this.searchOrders()
      this.statusList.map(it => { it.totalCount = null })
      this.statusList[this.currentTab].totalCount = this.totalRecord
    },
    async getPartners() {
      try {
        this.lstPartner = [];
        const { status, data } = await BaseService.getLogisticPartner();
        if (
          status === constants.statusCode.ok &&
          data !== null &&
          data.length > 0
        ) {
          this.lstPartner = data.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.partnerId === item.partnerId)
          );
          this.lstPartner.unshift({
            partnerId: null,
            partnerName: "Tất cả",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getShops() {
      const pars = {
        poId: this.selectPostOffice
      }
      const { status, data } = await UserService.getShops(pars);
      if (status === constants.statusCode.ok && data) {
        this.shopItems = data;
      }
    },
    async getListStatus() {
      try {
        const { status, data } = await OrderService.getListStatus();
        if (status === constants.statusCode.ok && data.length > 0) {
          this.statusItems = data;
          this.statusList = JSON.parse(JSON.stringify(data));
          this.statusList.unshift({ name: this.$t("all"), code: null, totalRecord: null });
          this.statusList.forEach((it) => {
            it.totalCount = null;
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        await this.searchOrders();
      }
    },
    async searchPostOffice() {
      const pars = this.bindRequestParsPostOffice();
      const { status, data } = await PostService.getPostOffice(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        if (data.totalRecord > 0) {
          this.lstPostOffice = data.data;
        } else {
          this.lstPostOffice = [];
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    bindRequestParsPostOffice() {
      const pars = {
        keyword: "",
        status: 1,
        limit: 1000,
        currentPage: this.page,
      }
      return pars
    },
    onChangeOffice() {
      this.shopSelected = null
      this.getShops()
    },
    addBreadCrumbs(pathActive) {
      const breadCrumbs =
        DataBreadCrumbUtils.getBreadCrumbByPathActive(pathActive);
      this.setBreadcrumbs(breadCrumbs);
    },
    onSelectedDates() {
      this.$refs.menu.save(this.dates);
    },
    formatDate(date) {
      return DateTimeUtils.formatDateTime(date, constants.formatDate);
    },
    parseDate(date) {
      return DateTimeUtils.parseDate(date);
    },
    onShowConfirmDeleteItems() {
      if (!this.checkStatusOrdersBeforeDel()) {
        this.message = this.$t("cancel_this_orders");
        this.typeNoti = constants.typeAlert.warning;
        this.showConfirmDel = true;
      } else {
        this.toggleDialogNoti({
          state: true,
          msg: this.$t("order_invalid_status"),
        });
      }
    },
    checkStatusOrdersBeforeDel() {
      if (this.countSelected === 0) return false;

      const arrSelected = this.selected;
      let isDiffWait = false;
      arrSelected.forEach((item) => {
        if (item.status !== constants.orderStatus.waiting) {
          isDiffWait = true;
        }
      });

      return isDiffWait;
    },
    onCancelConfirmDel() {
      this.showConfirmDel = false;
      this.message = "";
    },
    async onAcceptConfirmDel() {
      this.showConfirmDel = false;
      const items = this.selected;
      if (items !== null && items.length > 0) {
        this.showLoading = true;
        const pars = this.bindRequestOrdersCancel(items);
        const { status, data } = await OrderService.cancel(pars);
        this.showLoading = false;
        if (status === constants.statusCode.ok) {
          this.selected = [];
          let msgNoti = "";
          msgNoti = data;
          this.toggleDialogNoti({ state: true, msg: msgNoti });
          setTimeout(() => {
            this.toggleDialogNoti();
          }, 1800);
          await this.searchOrders();
        } else if (StorageService.checkResponseExpried(status, data)) {
          this.showConfirmTokenExpried = true;
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message });
          setTimeout(() => {
            this.toggleDialogNoti();
          }, constants.timeOut);
        }
      }
    },
    bindRequestOrdersCancel(items) {
      const ids = [];
      items.forEach((item) => {
        ids.push(item.id);
      });
      const pars = {
        ordersIds: ids,
      };

      return pars;
    },
    async onViewWayBillNewTab() {
      this.showLoading = true;
      const items = this.selected;
      const pars = this.bindRequestOrdersCancel(items);
      const { status, data } = await ReportService.wayBill(pars);
      if (status === constants.statusCode.ok) {
        await this.downloadFile(data.url);
        this.showLoading = false;
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.showLoading = false;
        this.toggleDialogNoti({ state: true, msg: data.message });
        setTimeout(() => {
          this.toggleDialogNoti();
        }, constants.timeOut);
      }
    },
    async downloadFile(url) {
      const { status, data } = await ReportService.reportFiles(url);
      if (status === constants.statusCode.ok) {
        var fileUrl = URL.createObjectURL(
          new Blob([data], { type: "application/pdf" })
        );
        window.open(fileUrl, "_blank");
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: "" }
    ) {
      this.showNoti = pars.state;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
    onInputDateRange(val) {
      if (!val) {
        this.dates = [];
      }
    },
    formatDateView(val) {
      return DateTimeUtils.formatDateTime(val, constants.formatDate);
    },
    formatDecimalView(val) {
      return val !== undefined ? StringUtils.formatDecimal(val) : '';
    },
    getHrefLinkById(id) {
      if (!this.checkRole) {
        const routeData = this.$router.resolve({
          path: routePaths.ORDER_DETAIL,
          query: { id: id },
        });
        return routeData.href;
      }
    },
    onchangeRecord(val) {
      this.itemsPerPage = val;
      this.searchOrders();
    },
    getPaymentByText(val) {
      return val === this.serviceFeePayersConst.sender
        ? this.$t("sender")
        : this.$t("receiver");
    },
    onScroll(e) {
      this.timeout && clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const heightContent = document.querySelector(
          "#virtual-scroll-table > .v-data-table__wrapper tbody"
        ).offsetHeight;

        const scrollTop = heightContent - this.computedHeightTable;
        if (e.target.scrollTop >= scrollTop && this.isLoadMore) {
          this.isScroll = true;
          this.page = this.page + 1;
          this.searchOrders();
        }
      }, 20);
    },
    // export excell
    async handleDownload() {
      this.showLoading = true;
      let dataExport = [];
      if (this.selected && this.selected.length > 0) {
        dataExport = this.selected;
      } else {
        dataExport = await this.getAllRecordByCondition();
      }
      const results = this.prepareDataExport(dataExport);
      const XLSX = require("xlsx");
      const workSheet = XLSX.utils.json_to_sheet(results);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(workSheet, this.headingExport);

      // Starting in the second row to avoid overriding and skipping headers
      XLSX.utils.sheet_add_json(workSheet, results, {
        origin: "A2",
        skipHeader: true,
      });
      XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");
      XLSX.write(workBook, {
        bookType: this.typeExportSelected,
        type: "buffer",
      });
      XLSX.write(workBook, {
        bookType: this.typeExportSelected,
        type: "binary",
      });
      XLSX.writeFile(workBook, this.getFileName);
      this.showLoading = false;
    },
    async getAllRecordByCondition() {
      this.showLoading = true;
      const pars = this.bindRequestPars();
      pars.currentPage = 1;
      pars.limit = constants.maximumRecordExport;
      const { status, data } = await OrderService.searchOrders(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok) {
        return data.data;
      } else {
        return [];
      }
    },
    getTextTruncate(val, size) {
      if (val.length > size) {
        return val.substring(0, size) + "...";
      } else {
        return val;
      }
    },
    prepareDataExport(arrData) {
      const results = [];
      arrData.forEach((item) => {
        var checkAddress = item.receiverAddress.includes(",")
        const newItem = {
          shopName: item.shopName,
          deliveryCode: item.deliveryCode,
          partnerName: item.partnerName,
          createdAt: DateTimeUtils.formatDateTime(
            item.createdAt,
            constants.formatDate
          ),
          updatedAt: DateTimeUtils.formatDateTime(
            item.updatedAt,
            constants.formatDate
          ),
          statusDescription: item.statusDescription,
          senderName: item.senderName,
          senderAddress: item.senderAddress,
          senderProvinceName: item.senderProvinceName,
          senderPhone:
            this.typeExportSelected === constants.typeExport.csv
              ? `'${item.senderPhone}`
              : item.senderPhone,
          receiverAddress: checkAddress ? item.receiverAddress : `${item.receiverAddress},${item.receiverCommuneName},${item.receiverDistrictName},${item.receiverProvinceName}`,
          receiverProvinceName: item.receiverProvinceName,
          receiverName: item.receiverName,
          receiverPhone:
            this.typeExportSelected === constants.typeExport.csv
              ? `'${item.receiverPhone}`
              : item.receiverPhone,
          description: item.description,
          orderValue: item.orderValue,
          weight: item.weight ? StringUtils.numStrToFloat(
            item.weight.toFixed(constants.maximumAfterDecimal)
          ) : null,
          calculateWeight: item.calculateWeight ? StringUtils.numStrToFloat(
            item.calculateWeight.toFixed(constants.maximumAfterDecimal)
          ) : null,
          codValue: item.codValue ? item.codValue : 0,
          shipValue: item.shipValue ? item.shipValue : 0,
          serviceFeesValue: item.serviceFeesValue ? item.serviceFeesValue : 0,
          totalServiceValue: item.serviceFeesValue ? item.serviceFeesValue + item.shipValue : item.shipValue
        };
        if (item.insuranceValue && item.insuranceValue !== 0) {
          newItem["assurance"] = "Có"
        } else {
          newItem["assurance"] = "Không"
        }
        if (!item.insuranceValue || item.insuranceValue !== null) {
          newItem["insuranceValue"] = item.insuranceValue !== 0 ? item.insuranceValue : ''
        }
        if (item.paymentBy === constants.serviceFeePayers.sender) {
          newItem["paymentBy"] = "Người gửi";
          newItem["freightPayer"] = item.senderName
          newItem["totalFee"] = item.codValue ? item.codValue : 0;
        } else {
          newItem["paymentBy"] = "Người Nhận";
          newItem["freightPayer"] = item.receiverName;
          newItem["totalFee"] = item.serviceFeesValue && item.codValue
            ? item.codValue + (item.serviceFeesValue + item.shipValue)
            : item.codValue
              ? item.codValue + item.shipValue
              : item.serviceFeesValue
                ? item.serviceFeesValue + item.shipValue : item.shipValue;
        }
        results.push(newItem);
      });
      return results;
    },
  },
};
</script>
<style src="@/styles/postOffice.scss" lang="scss">
</style>

